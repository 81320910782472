/**
 * Part of ankecare project.
 *
 * @copyright  Copyright (C) 2021 __ORGANIZATION__.
 * @license    __LICENSE__
 */

import '@unicorn/unicorn.js?e2246aa46e1e1431bf53931d';

const btn = $('.j-favorite-btn');

btn.on('click', (e) => {
  const $this = $(e.currentTarget);
  const type = $this.data('type');
  const targetId = $this.data('targetid');
  const refresh = $this.data('refresh');
  const router = parseInt($this.attr('data-favorited'))
    ? u.route('ajax_un_favorite')
    : u.route('ajax_favorited');

  u.$http.post(router, {type: type, targetId: targetId })
    .then(function (response) {
      const favorited = response.data.data

      const targetStr = '[data-type="' + type +'"]' + '[data-targetid="' + targetId +'"]';
      const target = $('.j-favorite-btn'+ targetStr);

      target.attr('data-favorited', favorited);

      if (favorited) {
        target.children('.c-favorite__favorited').removeClass('d-none');
        target.children('.c-favorite__unfavorite').addClass('d-none');
      } else {
        target.children('.c-favorite__favorited').addClass('d-none');
        target.children('.c-favorite__unfavorite').removeClass('d-none');
      }

      if (refresh) {
        window.location.reload();
      }
    })
    .catch(function (e) {
      console.error(e);
    });
});
